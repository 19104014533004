/* eslint-disable react/prop-types,react/display-name */
import { graphql } from 'gatsby';

import BlogListPage from '../templates/BlogListPage';

export const fragment = graphql`
	fragment BlogNode on Mdx {
		id
		body
		fields {
			slug
		}
		frontmatter {
			topics {
				slug
				name
				description
				lang
				alternates {
					lang
					slug
					name
					description
				}
			}
			author {
				fullName
				image {
					childImageSharp {
						gatsbyImageData(layout: CONSTRAINED, width: 32, height: 32)
					}
				}
			}
			thumbnail {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
			title
			subtitle
			description
			published
		}
		timeToRead
		parent {
			... on File {
				name
			}
		}
	}
`;

export const pageQuery = graphql`
	query BlogPageQuery($language: String) {
		allTopicsJson {
			edges {
				node {
					slug
					name
					lang
					description
					alternates {
						lang
						slug
						name
						description
					}
				}
			}
		}
		featuredPosts: allMdx(
			limit: 3
			sort: {
				fields: [frontmatter___featuredPost, frontmatter___published]
				order: [ASC, DESC]
			}
			filter: {
				fields: { source: { eq: "blog" } }
				frontmatter: { lang: { in: [$language] } }
			}
		) {
			edges {
				node {
					...BlogNode
				}
			}
		}
		allPosts: allMdx(
			sort: { fields: [frontmatter___published], order: DESC }
			filter: {
				fields: { source: { eq: "blog" } }
				frontmatter: { lang: { in: [$language] } }
			}
		) {
			edges {
				node {
					...BlogNode
				}
			}
		}
	}
`;
export default BlogListPage;
